import CloseIcon from "@mui/icons-material/Close";
import CollectionsIcon from "@mui/icons-material/Collections";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import ExploreIcon from "@mui/icons-material/Explore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import RoomIcon from "@mui/icons-material/Room";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import { FavouriteButton } from "components/FavouriteProductButton";
import Text, { TextSubtitle2 } from "components/Text";
import TextInput from "components/TextInput";
import useDebounce from "hooks/useDebounce";
import { useEntityConfigurationValueByKey } from "hooks/useEntityConfiguration";
import useSearch from "hooks/useSearch";
import { useNextTranslation } from "hooks/useTranslation";
import { useSearchParams } from "next/navigation";
import { useCallback, useState } from "react";

import { Flex } from "@holibob-packages/ui-core/components";
import { Link } from "@holibob-packages/ui-core/navigation";
import { usePathname } from "@holibob-packages/ui-core/navigation";
import { makeStyles } from "@holibob-packages/ui-core/style";

const useStyles = makeStyles()((theme) => ({
    container: {
        position: "relative",
        backgroundColor: "#FFF",
        borderRadius: 4,
        width: "100%",
    },
    dropdownContainer: {
        position: "absolute",
        left: 0,
        right: 0,
        top: "100%",
        zIndex: 30,
        opacity: 1,
        border: `solid 1px ${theme.palette.primary.main}`,
        backgroundColor: "white",
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        overflow: "hidden",
        marginTop: -2,
    },
    result: {
        padding: "8px 10px",
        minHeight: 30,
        // transition: "background 0.3s",
        backgroundColor: "white",
        borderBottom: "1px solid #EEE",
        color: theme.palette.primary.main,
        "& .SearchTitle": {
            // transition: "all 0.8s",
            color: "#666",
        },
        "& .SearchSubtitle": {
            // transition: "all 0.8s",
            color: "#AAA",
            fontSize: 14,
        },
    },
    resultHref: {
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            cursor: "pointer",

            "& .SearchTitle, & .SearchSubtitle": {
                color: theme.palette.primary.contrastText,
            },
        },
        "& > a": {
            textDecoration: "none",
        },
    },
    iconContainer: {
        paddingRight: 10,
    },
}));
export const SearchResultTitle = (props: $TSFixMe) => <Text className={"SearchTitle"} {...props} />;

export const SearchResultSubtitle = (props: $TSFixMe) => <TextSubtitle2 className={"SearchSubtitle"} {...props} />;

export const SearchResult = ({ icon, title, subtitle, href, type }: $TSFixMe) => {
    const { classes, cx } = useStyles();
    const resultNode = (
        <Flex middle className={cx(classes.result, href && classes.resultHref)}>
            <Box display="flex" flexDirection="row" justifyContent="flex-start">
                {icon && <div className={classes.iconContainer}>{icon}</div>}
                <div>
                    <SearchResultTitle>{title}</SearchResultTitle>
                    {subtitle?.length && <SearchResultSubtitle>{subtitle}</SearchResultSubtitle>}
                </div>
            </Box>
        </Flex>
    );

    if (!href) return resultNode;

    const dataTestId = `search-${type}`;
    return (
        <Link href={href} data-testid={dataTestId} data-tag="navigation-link" tabIndex={0}>
            {resultNode}
        </Link>
    );
};

export const SearchResultDestination = (props: $TSFixMe) => {
    const { id } = props;
    const href = `/destination/${id}`;
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    const icon = <ExploreIcon size={"large"} />;
    return <SearchResult href={href} icon={icon} {...props} />;
};

export const SearchResultPlace = (props: $TSFixMe) => {
    const { id } = props;
    const href = `/place/${id}`;
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    const icon = <RoomIcon size={"large"} />;
    return <SearchResult href={href} icon={icon} {...props} />;
};

export const SearchResultMatch = (props: $TSFixMe) => {
    const { id } = props;
    const filter = { search: id };
    const href = `/product?filter=${JSON.stringify(filter)}`;
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    const icon = <SearchIcon size={"large"} />;
    return <SearchResult href={href} icon={icon} {...props} />;
};

export const SearchResultFavourites = (props: $TSFixMe) => {
    const href = "/favourites";
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    const icon = <FavoriteIcon size={"large"} />;
    return <SearchResult href={href} icon={icon} {...props} />;
};

export const SearchResultProduct = (props: $TSFixMe) => {
    const { id } = props;
    const href = `/product/${id}`;

    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    const icon = <LoyaltyIcon size={"large"} />;
    return <SearchResult href={href} icon={icon} {...props} />;
};

export const SearchResultProductCategory = (props: $TSFixMe) => {
    const { id } = props;
    const href = `/category/${id}`;
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    const icon = <CollectionsIcon size={"large"} />;
    return <SearchResult href={href} {...props} icon={icon} />;
};
export const SearchResultCuration = (props: $TSFixMe) => {
    const { id } = props;
    const href = `/curation/${id}`;
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    const icon = <CollectionsBookmarkIcon size={"large"} />;
    return <SearchResult href={href} {...props} icon={icon} />;
};

export const SearchResultProductType = SearchResult;

const typeMap = {
    CURATION: SearchResultCuration,
    REGION: SearchResultPlace,
    COUNTRY: SearchResultPlace,
    CITY: SearchResultPlace,
    DESTINATION: SearchResultDestination,
    PRODUCT: SearchResultProduct,
    PRODUCT_CATEGORY: SearchResultProductCategory,
    PRODUCT_TYPE: SearchResultProductType,
    MATCH: SearchResultMatch,
    FAVOURITES: SearchResultFavourites,
};

export const SearchResultType = (props: $TSFixMe) => {
    const { type } = props;

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const ResultComponent = typeMap[type] || SearchResult;

    return <ResultComponent {...props} />;
};

export const SearchResults = ({ visible, results, showResults, onNavigate }: $TSFixMe) => {
    const { classes } = useStyles();

    if (!visible || results.length === 0 || !showResults) return null;

    return (
        <div className={classes.dropdownContainer} onClick={onNavigate}>
            {results.map((result: $TSFixMe) => (
                <SearchResultType key={result.title} {...result} />
            ))}
        </div>
    );
};

export const SearchFavouriteButton = ({ onClick, show }: $TSFixMe) => {
    const pathname = usePathname();

    if (!show) return null;

    return (
        <Tooltip title="My Favourites">
            <IconButton onClick={onClick} size="large">
                <FavouriteButton isFavourite={pathname === "/favourites"} />
            </IconButton>
        </Tooltip>
    );
};

export const SearchClearButton = ({ onClick }: $TSFixMe) => {
    return (
        <Tooltip title="Clear Search">
            <IconButton onClick={onClick} aria-label="search" size="large">
                <CloseIcon />
            </IconButton>
        </Tooltip>
    );
};

export const Search = ({
    onChange: onChangeProp,
    onSubmit: onSubmitProp,
    onClear: onClearProp,
    onNavigate,
    label,
    showLabel = true,
    showResults = true,
    showPlaceholder = true,
    ...props
}: $TSFixMe) => {
    const { classes } = useStyles();
    const [t] = useNextTranslation("search");
    const searchLabel = t("label.startSearch");
    props.label = searchLabel;
    const searchPlaceHolder = useEntityConfigurationValueByKey("SEARCH_PLACEHOLDER.SEARCH");

    if (showPlaceholder) {
        props.placeholder = searchPlaceHolder;
        props.label = searchPlaceHolder;
    }
    const searchParams = useSearchParams();

    const filter = searchParams.has("filter") ? JSON.parse(searchParams.get("filter")!) : {};
    const { search = "" } = filter;
    if (showLabel) props.label = label;

    const [value, setValue] = useState(search);
    const [focused, setFocussed] = useState(!search.length);

    const onClear = useCallback(() => {
        if (onClearProp) onClearProp();
        setValue("");
    }, [onClearProp]);

    const valueDebounced = useDebounce(value, 300);

    const { results = [], loading } = useSearch({
        variables: { query: valueDebounced },
        skip: !showResults,
    });

    return (
        <div className={classes.container}>
            <TextInput
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" aria-label="directions">
                            {loading && <CircularProgress size={30} />}
                            {value && <SearchClearButton onClick={onClear} />}
                        </InputAdornment>
                    ),
                }}
                value={value}
                onChange={(event) => {
                    const { value } = event.target;
                    if (onChangeProp) onChangeProp(value);
                    setValue(value);
                }}
                onFocus={() => {
                    setFocussed(true);
                }}
                onBlur={(event) => {
                    const dataset = (event.relatedTarget as HTMLBRElement | undefined)?.dataset ?? {};
                    if (dataset.tag !== "navigation-link") setFocussed(false);
                }}
                onKeyPress={(event) => {
                    if (event.key === "Enter") {
                        event.preventDefault();
                        if (onSubmitProp) onSubmitProp(value);
                    }
                }}
                variant="outlined"
                fullWidth
                {...props}
            />
            <SearchResults onNavigate={onNavigate} visible={focused} results={results} showResults={showResults} />
        </div>
    );
};

export default Search;
