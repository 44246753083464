import { ForwardedRef, forwardRef } from "react";
import { MarkRequired } from "ts-essentials";

import { CurationProductFragment, useCurationProductsQuery } from "../../apiHooks/graphql";
import { ProductsContainerSlider, ProductsContainerSliderProps } from "../../product/ProductsContainerSlider";
import {
    HbmlCurationProductsSliderNode,
    HbmlCurationProductsV2Props,
} from "../HbmlComponents/HbmlCurationProductsV2Node";
import { MarketPlaceProductTileVerticalSkeleton } from "../MarketPlace/MarketPlaceTile/MarketPlaceProductTileVerticalSkeleton";
import { ScrollableContainerProps } from "../ScrollableContainer";

type CurationProductsSliderContainerProps = Omit<HbmlCurationProductsSliderNode, "mode"> &
    MarkRequired<HbmlCurationProductsV2Props, "ProductCardComponent"> &
    Omit<ProductsContainerSliderProps, "sliderItemWidth" | "children">;

export const CurationProductsSliderContainer = forwardRef(function Component(
    props: CurationProductsSliderContainerProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const { parentCurationId, maxProducts = 50, NoProductsComponent } = props;
    const { loading, data } = useCurationProductsQuery({
        variables: { curationId: parentCurationId!, pageSize: maxProducts },
        skip: !parentCurationId,
    });

    if (loading) {
        return <CurationProductsSliderSkeleton ref={ref} mode={props.mode} maxProducts={props.maxProducts} />;
    }

    const products = data?.productList?.nodes ?? [];

    if (products.length === 0) {
        return NoProductsComponent ? <NoProductsComponent /> : null;
    }

    return <CurationProductsSlider products={products} {...props} />;
});

type CurationProductsSliderProps = CurationProductsSliderContainerProps & {
    products: CurationProductFragment[];
};

const CurationProductsSlider = forwardRef(function Component(
    props: CurationProductsSliderProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const { products, ProductCardComponent, productHrefFactory, ...otherProps } = props;
    return (
        <ProductsContainerSlider {...otherProps} ref={ref}>
            {products.map((entry, index) => (
                <ProductCardComponent
                    {...entry}
                    key={index}
                    orientation="vertical"
                    href={productHrefFactory({ slug: entry.slug, id: entry.id })}
                    imageMaxWidth={264}
                />
            ))}
        </ProductsContainerSlider>
    );
});

type CurationProductsSliderSkeletonProps = ProductsContainerSliderProps & { maxProducts?: number };
export const CurationProductsSliderSkeleton = forwardRef(function Component(
    props: CurationProductsSliderSkeletonProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const _itemsCount = getSliderItemsCount(props.mode, props.maxProducts);
    const skeletons = Array.from({ length: _itemsCount });

    return (
        <ProductsContainerSlider {...props} ref={ref}>
            {skeletons.map((_, index) => (
                <MarketPlaceProductTileVerticalSkeleton key={index} imageMaxWidth={264} />
            ))}
        </ProductsContainerSlider>
    );
});

const ITEMS_COUNT_FULL_WIDTH = 10;
const ITEMS_COUNT_DEFAULT = 5;
function getSliderItemsCount(mode: ScrollableContainerProps["mode"], maxProducts?: number) {
    switch (mode) {
        case "full-width": {
            if (!maxProducts) return ITEMS_COUNT_FULL_WIDTH;
            return maxProducts > ITEMS_COUNT_FULL_WIDTH ? ITEMS_COUNT_FULL_WIDTH : maxProducts;
        }
        default: {
            if (!maxProducts) return ITEMS_COUNT_DEFAULT;
            return maxProducts > ITEMS_COUNT_DEFAULT ? ITEMS_COUNT_DEFAULT : maxProducts;
        }
    }
}
