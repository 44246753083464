import { useEntityConfigurationValueByKey } from "hooks/useEntityConfiguration";

import { ProductCard, ProductCardProps } from "@holibob-packages/ui-core/product";

import { useFavouriteButton } from "../hooks/useFavouriteButton";
import { useViewerIsHolibobAdmin } from "../hooks/useViewer";

type ProductCardNewProps = Omit<ProductCardProps, "onFavouriteChange" | "isFavourite" | "newTab">;

export function ProductCardNew({ showFavouriteButton, showCopyProductId, ...props }: ProductCardNewProps) {
    const { isAvailable, setIsFavourite, isFavourite } = useFavouriteButton(
        props.product.id,
        props.product.isFavourite
    );
    const isHolibobAdmin = useViewerIsHolibobAdmin();

    const finalShowFavouriteButton = (showFavouriteButton ?? true) && isAvailable;
    const finalShowCopyProductId = (showCopyProductId ?? true) && isHolibobAdmin;
    const newTab = useEntityConfigurationValueByKey<boolean>("SETTING.PRODUCT_CARD_NEW_TAB");

    return (
        <ProductCard
            {...props}
            showFavouriteButton={finalShowFavouriteButton}
            showCopyProductId={finalShowCopyProductId}
            onFavouriteChange={setIsFavourite}
            isFavourite={isFavourite}
            newTab={newTab}
        />
    );
}
