import { Skeleton } from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { MarkRequired } from "ts-essentials";

import { urlCuration } from "@holibob-packages/url";
import { AssetUrl } from "@holibob-packages/vault";

import {
    CurationProductFragment,
    useCurationListWithProductCountQuery,
    useCurationProductsQuery,
} from "../../apiHooks/graphql";
import { CurationContentItemCurationWithProductCountFragment } from "../../gql-request";
import { useNextTranslation } from "../../hooks";
import { HbmlCurationProductsSplitScreenNode, HbmlCurationProductsV2Props } from "../HbmlComponents";
import { MarketPlaceSection } from "../MarketPlace/MarketPlaceSection";
import { MarketPlaceProductTileHorizontalSkeleton } from "../MarketPlace/MarketPlaceTile/MarketPlaceProductTileHorizontalSkeleton";
import { ProductListContentWrapper } from "../ProductListContent";
import { ResponsiveImage } from "../ResponsiveImage";
import { Typography } from "../Typography";

const DEFAULT_PRODUCTS_COUNT = 3;

type CurationProductsSplitScreenContainerProps = Omit<HbmlCurationProductsSplitScreenNode, "mode"> &
    MarkRequired<HbmlCurationProductsV2Props, "ProductCardComponent">;

export const CurationProductsSplitScreenContainer = (props: CurationProductsSplitScreenContainerProps) => {
    const { parentCurationId, NoProductsComponent } = props;
    const variables = { curationId: parentCurationId!, pageSize: DEFAULT_PRODUCTS_COUNT };
    const { loading, data } = useCurationProductsQuery({ variables, skip: !parentCurationId });

    const { loading: loadingCurationContent, data: parentCurationContent } = useCurationListWithProductCountQuery({
        variables: parentCurationId ? { filter: { curationIdList: [parentCurationId] } } : undefined,
    });

    if (loading || loadingCurationContent) {
        return <CurationProductsSplitScreenSkeleton splitScreenImagePlacement={props.splitScreenImagePlacement} />;
    }

    const products = data?.productList?.nodes ?? [];
    if (products.length === 0) {
        return NoProductsComponent ? <NoProductsComponent /> : null;
    }

    const curations = parentCurationContent?.hierarchyCurationList?.nodes ?? [];
    const curation = curations.length > 0 ? curations[0] : undefined;

    if (!curation) return null;

    return <CurationProductsSplitScreen products={products} curation={curation} {...props} />;
};

type CurationProductsSplitScreenProps = CurationProductsSplitScreenContainerProps & {
    products: CurationProductFragment[];
    curation: CurationContentItemCurationWithProductCountFragment;
};

function CurationProductsSplitScreen(props: CurationProductsSplitScreenProps) {
    const { curation, splitScreenImagePlacement = "left", products, ProductCardComponent, productHrefFactory } = props;
    const [t] = useNextTranslation("hbml");
    const theme = useTheme();
    const md = theme.breakpoints.values.md;

    const sizes = `(min-width: ${md}px) 50vw, 100vw`;

    const imageAssetUrl = curation.heroImageAsset
        ? AssetUrl.fromString(curation.heroImageAsset.uri).unwrap()
        : undefined;

    const curationHref = urlCuration({ curationId: curation.id, slug: curation.slug });

    return (
        <ProductsContainerSplitScreen data-image-placement={splitScreenImagePlacement}>
            <ImageWrapper>
                <ImageTitle variant="headline" size="large">
                    {curation.name}
                </ImageTitle>
                {imageAssetUrl && (
                    <ResponsiveImageStyled
                        loading="lazy"
                        alt="curationImage"
                        src={imageAssetUrl}
                        sizes={sizes}
                        srcSetSizes={[320, 600, 900, 1200, 1600]}
                        height={600}
                    />
                )}
            </ImageWrapper>
            <ProductList data-image-placement={splitScreenImagePlacement}>
                <ProductListContentWrapper>
                    {products.map((entry) => (
                        <ProductCardComponent
                            {...entry}
                            key={entry.id}
                            orientation="horizontal"
                            href={productHrefFactory({ slug: entry.slug, id: entry.id })}
                            height={130}
                            imageMaxWidth={130}
                            isSmallTile={true}
                        />
                    ))}
                    <SeeAllButton component="a" href={curationHref} variant="outlined" rel="noopener">
                        {t("curationProducts.showAllButton.label", { count: curation.productCount })}
                    </SeeAllButton>
                </ProductListContentWrapper>
            </ProductList>
        </ProductsContainerSplitScreen>
    );
}

type CurationProductsSplitScreenSkeletonProps = Pick<CurationProductsSplitScreenProps, "splitScreenImagePlacement">;
function CurationProductsSplitScreenSkeleton(props: CurationProductsSplitScreenSkeletonProps) {
    const skeletons = Array.from({ length: DEFAULT_PRODUCTS_COUNT });

    return (
        <ProductsContainerSplitScreen data-image-placement={props.splitScreenImagePlacement}>
            <ImageSkeleton variant="rectangular" height={300} />
            <ProductList data-image-placement={props.splitScreenImagePlacement}>
                <ProductListContentWrapper>
                    {skeletons.map((_, index) => (
                        <MarketPlaceProductTileHorizontalSkeleton key={index} />
                    ))}
                    <Skeleton variant="rectangular" height={40} />
                </ProductListContentWrapper>
            </ProductList>
        </ProductsContainerSplitScreen>
    );
}

const ProductsContainerSplitScreen = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    overflow: "hidden",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        gap: theme.spacing(4),
        height: 600,
        "&[data-image-placement=right]": {
            flexDirection: "row-reverse",
        },
    },
    [theme.breakpoints.up("lg")]: {
        gap: theme.spacing(6),
        height: 720,
    },
}));

const ProductList = styled(MarketPlaceSection)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    boxSizing: "border-box",
    flex: 1,
    maxWidth: 720,
    [theme.breakpoints.up("md")]: {
        paddingBottom: "unset",
        "&[data-image-placement=left]": {
            marginInlineEnd: theme.spacing(4),
        },
        "&[data-image-placement=right]": {
            marginInlineStart: theme.spacing(4),
        },
    },
}));

const ImageWrapper = styled("div")(({ theme }) => ({
    position: "relative",
    height: 300,
    width: "100%",
    flexShrink: 0,
    [theme.breakpoints.up("md")]: {
        height: 600,
        width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
        height: 720,
    },
    "&::before": {
        content: '""',
        position: "absolute",
        backgroundColor: "rgba(0,0,0,0.2)",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
}));

const ImageTitle = styled(Typography)(({ theme }) => ({
    position: "absolute",
    textAlign: "center",
    color: theme.palette.common.white,
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    lineHeight: 1.4,
    maxWidth: `calc(100% - ${theme.spacing(5)})`,
    width: "max-content",
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
}));

const ResponsiveImageStyled = styled(ResponsiveImage)({
    img: {
        objectFit: "cover",
        width: "100%",
        height: "100%",
    },
});

const ImageSkeleton = styled(Skeleton)(({ theme }) => ({
    minHeight: 300,
    width: "100%",
    [theme.breakpoints.up("md")]: {
        minHeight: 600,
        width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
        minHeight: 720,
    },
}));

const SeeAllButton = styled(Button)(({ theme }) => ({
    borderRadius: 50,
    "&:link, &:-webkit-any-link": {
        borderColor: theme.palette.buttonPrimary.main,
        color: theme.palette.buttonPrimary.main,
    },
})) as typeof Button;
