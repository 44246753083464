import { CurationProductFragment } from "../apiHooks/graphql";
import { ProductsContainerGrid } from "../product";
import { ProductItem } from "../types";
import { CurationProductsGridContainerProps } from "./CurationProducts/CurationProductsGridContainer";

type CurationProductsGridProps = CurationProductsGridContainerProps & {
    products: CurationProductFragment[];
    onProductClick?: (productItem: ProductItem, position: number) => void;
};

export function CurationProductsGrid({
    products,
    ProductCardComponent,
    onProductClick,
    productHrefFactory,
    ...otherProps
}: CurationProductsGridProps) {
    return (
        <ProductsContainerGrid {...otherProps}>
            {products.map((entry, position) => (
                <ProductCardComponent
                    {...entry}
                    key={entry.id}
                    onProductClick={(item) => {
                        onProductClick?.(item, position);
                    }}
                    orientation="vertical"
                    tileContentMode="column"
                    href={productHrefFactory({ slug: entry.slug, id: entry.id })}
                />
            ))}
        </ProductsContainerGrid>
    );
}
