import { MarkRequired } from "ts-essentials";

import { useCurationProductsQuery } from "../../apiHooks/graphql";
import { CurationProductsGrid } from "../CurationProductsGrid";
import { CurationProductsGridSkeleton } from "../CurationProductsGridSkeleton";
import {
    HbmlCurationProductsGridNode,
    HbmlCurationProductsV2Props,
} from "../HbmlComponents/HbmlCurationProductsV2Node";

export type CurationProductsGridContainerProps = Omit<HbmlCurationProductsGridNode, "mode"> &
    MarkRequired<HbmlCurationProductsV2Props, "ProductCardComponent">;

export const CurationProductsGridContainer = (props: CurationProductsGridContainerProps) => {
    const { parentCurationId, maxProducts = 50, NoProductsComponent } = props;
    const variables = { curationId: parentCurationId!, pageSize: maxProducts };
    const { loading, data } = useCurationProductsQuery({ variables, skip: !parentCurationId });

    if (loading) {
        return <CurationProductsGridSkeleton maxProducts={maxProducts} />;
    }

    const products = data?.productList?.nodes ?? [];

    if (products.length === 0) {
        return NoProductsComponent ? <NoProductsComponent /> : null;
    }

    return <CurationProductsGrid products={products} {...props} />;
};
